@import '_fonts';
@import '_colors';
@import '_reset';

@import url("/node_modules/@ecats911/react-grid-layout/css/styles.css");
@import url("/node_modules/react-resizable/css/styles.css");
@import url("_handles.scss");

$primary-font: 'Roboto';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $primary-font, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

h1,
h2,
h3,
h4,
h5 {
    font-style: normal;
    font-weight: 600;
}

h1 {
    font-size: 56px;
    line-height: 58px;
}

h2 {
    font-size: 48px;
    line-height: 52px;
}

h3 {
    font-size: 32px;
    line-height: 36px;
}

h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

h5 {

    font-size: 20px;
    line-height: 22px;
}

p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $black;
}

.react-draggable {
    position: absolute;
    display: inline-block;
    cursor: move;
}

.component-wrapper {
    display: inline-flex;

    >div {
        margin-right: 5px;

        &:last-child {
            margin-right: unset;
        }
    }
}

.pointer {
    cursor: pointer;
}

.react-grid-item.react-grid-placeholder {
    background-color: $blue-dark;
}

.react-grid-item {
    &.cssTransforms {
        transition-property: none;
    }

    z-index: 0;

    &:hover{
        z-index: 1;
    }
}
.react-grid-item.react-draggable-dragging{
    z-index: unset;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-resizable-handle.react-resizable-handle-e,
.react-resizable-handle.react-resizable-handle-n,
.react-resizable-handle.react-resizable-handle-s {
    z-index: 210;
}

.animated {
    .react-grid-item{
        &.cssTransforms {
            transition-property: transform;
        }
    }
}

.form-control {
    &:focus {
        outline: none;
    }
}


.form-control {
    width: 100%;
    border: solid 1px $gray-1;
    background-color: $gray-0;
    padding: 5px;
    border-radius: 4px;

    &.warn {
        border-color: $red-dark;
    }
}

 

.validation-error {
    border-color: $red-dark;
}