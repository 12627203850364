@use './colors' as *;
@use './mixins' as *;

.settingConfigDialog{
    width: 100%;
  padding: 0.7rem;
}

.formContainer {
    display: flex;
}

.formField {
    width: 50%;
    padding: 5px;
    padding-bottom: 20px;
    margin-right: 30px;
}

.containerBorder {
    border: 1px solid #D1D1D1;
    padding: 20px;
    height: 540px;
    overflow-x: hidden;
}

.formLabel {
    color: $blue;
}

.formQuestion {
    color: #7E7E7E;
    font-weight: bold;
    margin-bottom: 15px;
}

.heading {
    margin-bottom: 5px;
}

.boxContainer {
    padding-bottom: 15px;
    display: flex;
}

.emailBox, .smsBox {
    height: 58px;
    width: 53px;
    text-align: center;
}

.emailBox {
    background: #E2F0F8 0% 0% no-repeat padding-box;
    margin-right: 15px;
    color: #2980B9;
}

.smsBox {
    border: 1px dashed #707070;
    opacity: 0.35;
}

.boxIcon {
    margin: 10px 15px 0px 15px;
}

.boxLabel {
    font-size: 13px;
}

.formControl {
    width: 100%;
    border: solid 1px #e2e2e2;
    padding: 10px;
    border-radius: 4px;
}

.warn {
    border-color: #B21E1E;
}

.warnSelect {
    border: 1px solid;
    border-radius: 5px;
    border-color: #B21E1E;
}

.infoBoxContainer {
    margin: 10px 0 20px 0;

    .infoBox {
        padding: 20px 15px;
        background-color: #E2F0F8;
        border-radius: 8px;
    }

    .infoHead {
        display: block;
        margin-bottom: 10px;
        color: #1E5F8A;
    }

    .infoBody {
        color: #7E7E7E;
    }
}