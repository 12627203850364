@use '../../scss/colors' as *;

.row {
    &:focus {
        outline: none !important;
    }

    padding: 0 0 14px;
    margin-bottom: 12px;
    border-bottom: solid 1px #ccc;

    &:last-child {
        border-bottom: unset;
        padding: 0 0 10px;
        margin-bottom: 10px;
        }

    .heading {
        padding: 10px 0 5px;
        display: flex;
        position: relative;
        align-items: center;

        label {
            color: $blue-dark;
            font-size: .9em;
        }
    }

    span {
        &.info {
            color: $blue;
            display: block;
            font-size: .7em;
            border: solid 2px $blue;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            text-align: center;
            padding: 1px 0px 0 0;
            margin-left: 10px;
            cursor: help;
        }
    }

    label {
        color: $blue-dark;
        font-size: .84em;
        display: block;
        margin-bottom: 4px;
    }
}

.select{

    label {
        display: block;
        color: #555;
        font-size: .84em;
        margin: 6px 0 0 2px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.help {
            cursor: help;
        }
    }
}

.dropdown {

    width: 100%;

    select {
        padding: 6px;
        background-color: $gray-input;
        border: solid 1px $gray-0;
        border-radius: 4px;
        display: block;
        width: 100%;
    }

    label {
        display: block;
        color: #555;
        font-size: .84em;
        margin: 6px 0 0 2px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.help {
            cursor: help;
        }
    }
}

.spacer {
	padding-bottom: 12pt;
}

.warn{
    margin: 7px 0 0 0;
    p{
        color: $red-dark;
        margin:0 0 0 7px;
        padding: 0;
        font-size: .82em;
    }
}

.errormessage {
    margin: 7px 0 7px 0;
    p {
        color: $red-dark;
        font-weight: bold;
    }
}

.successmessage {
    margin: 7px 0 7px 0;
    p {
        color: $blue-dark;
        font-weight: bold;
    }
}