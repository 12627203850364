
.option{
    width: inherit;
    display: flex;
    justify-content: center;
    
    span{
 
        cursor: pointer;
        display: inline-block;

        svg{
            margin: 0 0 0 10px;
        }
    }
}
