.container {
    position: relative;
    box-sizing: border-box;

    &.disabled {
        pointer-events: none;
    }

    .control {
        align-items: center;
        background-color: #F7F7F7;
        border-color: #ededed;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;

        .valueContainer {
            align-items: center;
            display: grid;
            flex: 1;
            flex-wrap: wrap;
            padding: 2px 8px;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;

            .placeholder {
                color: hsl(0, 0%, 50%);
                grid-area: 1/1/2/3;
                margin-left: 2px;
                margin-right: 2px;
                box-sizing: border-box;
            }

            .multiValue {
                background-color: hsl(0, 0%, 90%);
                border-radius: 2px;
                display: flex;
                margin: 2px;
                min-width: 0;
                box-sizing: border-box;
                width: 80%;

                .value {
                    border-radius: 2px;
                    color: hsl(0, 0%, 20%);
                    font-size: 10pt;
                    overflow: hidden;
                    padding: 3px;
                    padding-left: 6px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    box-sizing: border-box;
                    margin-bottom: 2px;
                }
            }
        }

        .IndicatorsContainer {
            align-items: center;
            align-self: stretch;
            display: flex;
            flex-shrink: 0;
            box-sizing: border-box;
            justify-content: space-between;

            .indicatorsContainer {
                color: #555;
                display: flex;
                padding: 8px;
                transition: color 150ms;
                box-sizing: border-box;

                svg {
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    fill: currentColor;
                    line-height: 1;
                    stroke: currentColor;
                    stroke-width: 10;
                    cursor: pointer;
                }

                .save {
                    color: #20638f;
                }

                .close {
                    color: currentColor;
                }
            }
        }

        &:hover {
            border-color: rgb(0, 0, 0, 0.3);
        }

        &:active {
            border-width: 2px;
            border-color: #2684FF;
        }
    }
}

.sortingOptions {
    color: #20638f;
    display: flex;
    font-size: 0.8em;
    //align-items: center;
    margin-top: 10px;
    cursor: pointer;
    justify-content: space-between;

    & span {
        & svg {
            margin-right: 5px;
        }
    }
}

.hidden {
    display: none;
    visibility: hidden;
}

.sortableContainer {
    background-color: #F7F7F7;
    padding: 10px;
    position: absolute;
    top: 0px;
    left: -100px;
    z-index: 999;
    width: 600px;
    height: 550px;
    //height: 100%;
    //position: absolute;
    //z-index: 200;
    //background-color: #ddd;
    //width: 500px;
    //min-height:200px;
    //left:-100px;

    /*::before{
        content: ' ';
        display: block;
        position: absolute;
        top:-400px;
        bottom:0px;
        left:0px;
        right: 500px;
        z-index: 180;
    }*/

    .title {
        color: #20638f;
        display: flex;
        font-size: 0.9em;
        align-items: center;
        padding-bottom: 15px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .sortButtons {
            button {
                border: solid 1px #ededed;
                border-radius: 4px;
                padding: 10px 10px 10px 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                min-width: 120px;
                text-align: left;

                svg {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            button:hover {
                background-color: #E2F0F8;
            }

            .selected {
                background-color: #E2F0F8;
            }
        }

        .cancelSaveButtons {
            button {
                border: solid 1px #ededed;
                border-radius: 4px;
                padding: 10px 10px 10px 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                min-width: 80px;
            }

            .confirmButton {
                background-color: #20638f;
                color: white;
            }
        }
    }

    .selectedItems {
        border: solid 1px #ededed;
        border-radius: 4px;
        width: 100%;
        height: 85%;
        padding: 10px;
        overflow-y: auto;

        .item {
            height: 30px;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            padding-left: 10px;
            padding-right: 10px;
            justify-content: space-between;
            font-size: 0.8em;

            svg {
                height: 12px;
                color: #626262;
                cursor: pointer;
            }
        }

        svg {
            height: 12px;
            color: #626262;
            cursor: pointer;
        }

        svg:hover {
            color: #ED1E1E;
        }

        .item:nth-child(odd) {
            background: #CCC;
        }

        .itemDragable {
            height: 30px;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            padding-left: 10px;
            padding-right: 10px;
            justify-content: space-between;
            font-size: 0.8em;
            cursor: pointer;
        }

        .itemDragableOdd{
            background: #CCC;
        }

        .itemDragableEven {
            background: #F7F7F7;
        }

        
    }
}