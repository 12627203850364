@import '_colors';
@import '_mixins';

.adminConfigDialog
{
  width: 100%;
  padding: 0.7rem;
  
  .bandwidthMultiplicatorInput {
    width: 14rem;
  }
}