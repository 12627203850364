.optionbold{
    font-weight: bold;
    margin-right: 5px;
    color:#555;

    &.selected{
        color:#fff;
    }
}
.optiondescription{
    color:#777;
    font-size: .9em;

    &.selected{
        color:#eee;
    }
}