@use './mixins' as *;

.container {
    width: inherit;
    height: inherit;

    .emptyState {
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;

        .emptyStateContainer {
            >div {
                &.emptyStateTitle {
                    span {
                        color: #1E5F8A;
                        font-weight: bold;
                        font-size: 20px;
                        user-select: none;
                    }

                    div {
                        padding-bottom: 10px;
                        text-align: center;
                    }
                }
            }

            span {
                color: #1E5F8A;
                font-weight: normal;
                font-size: 16px;
                font-weight: 430;
                display: block;
                user-select: none;
            }

            div {
                padding-bottom: 5px;
                text-align: center;
            }

            .morePadding {
                padding-top: 25px;
            }
        }

        .emptyStateImage {
            margin: auto;
            display: block;
            user-select: none;
        }

        .emptyStateButton {
            padding-top: 8px;
            margin: auto;
            display: block;
            cursor: pointer;
            user-select: none;

            &:hover {
                filter: brightness(115%);
            }
        }
    }
}

.widgetcontainer {
    box-shadow: 0px 4px 15px -1px rgb(0, 0, 0, 0.2);

    .genericContainer {
        overflow: hidden;
        height: inherit;
        position: relative;
        display: flex;
        flex-direction: column;

        .warning {
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            >div {
                display: flex;
                flex-direction: column;
                height: 100%;
                align-items: center;
                justify-content: center;

                .x{
                    display: block;
                    height:35px;
                    width: 35px;
                    border-radius: 50%;
                    background-color: #DE4D44;
                    margin: 0 0 10px;
                    position: relative;

                    &::before{
                        content: ' ';
                        display: block;
                        position: absolute;
                        height: 20px;
                        width: 2px;
                        background-color: #fff;
                        left:17px;
                        top:8px;
                        transform: rotate(45deg);
                    }
                    &::after{
                        content: ' ';
                        display: block;
                        position: absolute;
                        height: 20px;
                        width: 2px;
                        background-color: #fff;
                        left:17px;
                        top:8px;
                        transform: rotate(-45deg);
                    }
                }


                p {
                    text-align: center;
                    font-weight: bold;
                }
            }


        }

        .layoutGenericContainer {
            height: inherit;
            position: relative;
            background-color: #dedaeb;
            flex-grow: 1;

            *>div:not([class^="gm-style"] *> div) {
                background-color: #fff;
            }
        }

        .containerTitle {
            padding: 6px 0 0 6px;
            font-weight: bold;
            background: rgb(222, 218, 235);
            word-wrap: break-word;
        }
    }

    .sortingContainer {
        height: 100%;
        width: 100%;
        background: rgb(239, 240, 243);

        .containerTitle {
            padding: 6px 0 0 6px;
            font-weight: bold;
            background: rgb(239, 240, 243);
            box-shadow: -1px 4px 11px -2px rgba(31, 31, 31, 0.46);
            -webkit-box-shadow: -1px 4px 11px -2px rgba(31, 31, 31, 0.46);
            -moz-box-shadow: -1px 4px 11px -2px rgba(31, 31, 31, 0.46);
            -webkit-box-shadow: -1px 4px 11px -2px rgba(31, 31, 31, 0.46);
            -moz-box-shadow: -1px 4px 11px -2px rgba(31, 31, 31, 0.46);
            position: relative;
            z-index: 10;
            word-wrap: break-word;
        }

        .sortingContainerTitle {
            padding-bottom: 5px;
        }

        .genericContainer {
            overflow: hidden;
            height: inherit;
            position: relative;
            display: flex;
            flex-direction: column;
            border: dotted #555 1px;
            border-radius: 5px;
            margin: 5px;

            .layoutGenericContainer {
                background: rgb(218, 225, 235);
                position: relative;
                overflow: hidden;

                flex-grow: 1;

                *>div:not([class^="gm-style"] *> div) {
                    background-color: #fff;
                }
            }
        }
    }
}