@use '../scss/colors' as *;
.container{
    
    background-color: #f0f0f0;
    height: 100vh;
    overflow: auto;

    h3{
        margin:100px 0 0 0;
        color:$blue-dark;
        text-align:center;
    }

    img{
        display: block;
        margin: 10% auto 0;
    }
}