.container {
    
    height: inherit;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: flex-start;

    .masterContainer {
        width: 100%;                

        .react-resizable-handle-s {
            position: absolute;
            width: 50px;
            height: 50px;
        }
    }

    .replicas {
        width: 100%;
        height: auto;
        overflow: hidden;
        
        .replica {
            box-sizing: border-box;
        }
    }

    .replicaWarning {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        margin: auto;
        text-align: center;
    }

    .item {
        width: 100%;       
        flex-shrink: 0;        
        background-color: #ededed;
        padding: 4px;



        >label {
            display: block;
            font-size: 1.1em;
            color: rgb(0, 127, 177);
            margin: 3px 0 0 7px;
        }

        margin-bottom: 10px;


        .grid {
            box-sizing: border-box;        
        }
    }
}