.inputarea {

    &.data {
        input {
            margin-top: 10px;
        }
    }

    input {
        width: 30%;
    }

    span {
        display: block;
        margin-top: 6px;
        font-size: .72em;
        color: #555;
    }
}

.fontcontrol {
    width: 100%;
    margin: 20px 0 0;
    display: flex;
    align-items: flex-end;
    .option {        
        margin-right: 5px;
        &:last-child {
            margin-right: unset;
        }

        &.type {
            width: 50%;
        }
        &.size {
            width: 25%;
        }
 
    }    
}

.coptions{
    display: flex;
    width: 100%;
    padding-top: 15px;

    .color{
        flex-shrink: 0;
        width: 50%;
    }
}