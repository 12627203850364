@use './colors' as *;

.login{
    padding:20px;
    background-color: #f0f0f0;
    height: 100vh;
    
    h3{
        text-align: center;
        color:$blue;
        margin-top: 6%;
    }

    .signin{
        &> div{
            width: 100%;
        }
        &> button{
            flex-shrink: 0;
            margin-left:5px;
        }
        width: 300px;
        margin: 80px auto 0;
        display: flex;
        
    }
}

.authentication{
    button{

        display: block;
        padding: 10px;
        background-color: $blue-dark;
        border:solid 1px $blue-dark;
        border-radius: 4px;
        color:#fff;
        cursor: pointer;
        &:hover{
            filter:brightness(120%);
        }
        &:active{
            filter:brightness(80%);
        }
        &:disabled{
            background-color: #aaa;
            cursor: wait;
        }
    }
}

.error{
    margin:100px auto 0;
    padding: 10px;
    width: 800px;
    border: solid 1px #cb4027;
    background-color: #fff;
    border-radius: 3px;
    
    p{
        color:#cb4027;
    }
}

