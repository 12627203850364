@use './colors' as *;
@use './mixins' as *;

.container { 
    @include noselect;
    height: 100%;
    .controls{
        cursor: move;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 20px;
        z-index: 200;
   
        background-color: #f0f0f0;
        transition: all linear .2s;

        display: flex;
        justify-content: space-between;

        padding:4px 5px 4px 4px;        
        .ls{

        }
        .rs{
            display: flex;
            justify-content: flex-end;
        }

        .btn{
            background:none;
            border:none;
            height: 16px;
            width: 16px;
            cursor: pointer;
            margin-right: 10px;
            &:last-child{
                margin-right: unset;
            }

            svg{
                color:$blue-dark;
            }
            &.delete{
                svg{
                    color:$red-threshold;
                }
                margin-right: 5px;
            }
        }
 
    }

    &:hover{
        .controls{
            opacity: 85%;
        }
    }
}
