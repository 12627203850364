@use '../../../scss/colors' as *;

.message {
    span {
        color: $blue;
        font-size: .8em;
    }
}

.messageMarginBottom {
    margin-bottom: 10px;
}

.inputarea {

    &.data {
        input {
            margin-top: 10px;
        }
    }

    input {
        width: 30%;
    }

    span {
        display: block;
        margin-top: 6px;
        font-size: .72em;
        color: #555;
    }
}

.threshold {
    display: flex;

    .inputarea {

        &.inputLeft {
            margin-right: 20px;
        }

        input {
            width: 100%;
        }

        span {
            display: block;
            margin-top: 6px;
            font-size: .72em;
            color: #555;
        }
    }
}

.fontcontrol {
    width: 100%;
    margin: 10px 0 0;
    display: flex;
    align-items: flex-end;

    .option {
        margin-right: 5px;

        &:last-child {
            margin-right: unset;
        }

        &.type {
            width: 50%;
        }

        &.size {
            width: 25%;
        }

    }
}

.coptions {
    display: flex;
    width: 100%;

    .color {
        flex-shrink: 0;
        width: 50%;
    }
}

.toptions {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;

    .threshold {
        flex-shrink: 0;
        width: 50%;
        font-size: .8em;
    }

    .disabled {
        opacity: 0.6;
    }
}

.thresholdConfig {
    width: 100%;
    color: #20638f;
    font-size: .8em;
    margin-bottom: 20px;
    cursor: pointer;

    svg {
        padding-right: 5px;
    }
}

.labels {
    .row {
        margin: 10px 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        label {
            color: $blue-dark;
            font-size: .84em;
            display: block;
            margin-bottom: 4px;
        }

        .name {
            flex-shrink: 0;
            margin-right: 5px;
            width: 100%;

            >div {
                display: flex;
                justify-content: space-between;

                span {
                    color: $blue;
                    cursor: pointer;
                    font-size: .74em;
                    margin: 0 5px 0 0;
                    text-decoration: underline;
                }
            }

            input[type="text"] {
                margin: 0 0 10px;
                height: 31px;
            }
        }
    }
}

.thresholdConfigContainer {
    background-color: #F7F7F7;
    padding: 10px;
    position: absolute;
    top: 0px;
    left: -100px;
    z-index: 999;
    width: 600px;
    height: 550px;
    color: #555;

    .header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 15px;

        .title {
            color: #20638f;
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            .button {
                border: solid 1px #ededed;
                border-radius: 0;
                min-height: 30px;
                min-width: 80px;

                &.cancel {
                    color: #555;
                    margin-right: 5px;
                }

                &.save {
                    background-color: #20638f;
                    color: white;
                }

                &.save:disabled {
                    background-color: rgba(5, 5, 5, 0.1);
                    color: #555;
                }
            }
        }
    }

    .content {
        border: solid 1px #ededed;
        border-radius: 4px;
        width: 100%;
        height: 90%;
        padding: 5px;
        font-size: 15px;
        overflow-y: auto;

        .title {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: bold;
            background-color: #ededed;
            padding-top: 5px;
            padding-bottom: 5px;

            .widthLong {
                padding-left: 5px;
                align-items: center;
                min-width: 50%;
                width: 50%;
                max-width: 50%;
            }

            .widthShort {
                padding-left: 5px;
                align-items: center;
                min-width: 25%;
                width: 25%;
                max-width: 25%;
            }
        }

        .data {
            font-size: 13px;
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            text-align: left;
            min-height: 30px;

            /*.dataRow {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                text-align: left;
                min-height: 30px;
            }

            .dataRowEven {
                background-color: #F7F7F7;
            }*/

            .dataRowOdd {
                background-color: #ededed;
            }

            .widthLong {
                padding-left: 5px;
                align-items: center;
                min-width: 50%;
                width: 50%;
                max-width: 50%;
            }

            .widthShort {
                padding-left: 5px;
                align-items: center;
                min-width: 25%;
                width: 25%;
                max-width: 25%;
            }

            .metric {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            .thresholdInput {
                width: 95%;
                padding-bottom: 2px;
            }
        }

        .widthMetricData {
            min-width: 80%;
            width: 80%;
            max-width: 80%;

            .widgetFull {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                text-align: left;
                align-items: center;
                min-width: 100%;
                width: 100%;
                max-width: 100%;
                padding-top: 2px;
                padding-bottom: 2px;

                .widthMetricName {
                    padding-left: 5px;
                    align-items: center;
                    min-width: 25%;
                    width: 25%;
                    max-width: 25%;
                }

                .widthMetricData {
                    padding-left: 5px;
                    align-items: center;
                    min-width: 18.75%;
                    width: 18.75%;
                    max-width: 18.75%;
                }
            }

            input[type="number"]:disabled {
                color: rgba(5, 5, 5, 0.3);
            }
        }

        .widthMetricDataDisabled {
            pointer-events: none;
            color: rgba(5, 5, 5, 0.3);
        }

        .widthLong {
            padding-left: 5px;
            align-items: center;
            min-width: 30%;
            width: 30%;
            max-width: 30%;
        }
    }
}