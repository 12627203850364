@import "../../scss/colors";
@import "../../scss/_mixins";

.container {
    @include shadow-1dp;
    @include noselect;
    position: absolute;
    z-index: 1000;
    left: 0;
    min-width: 250px;
    max-width: 250px;

    border-radius: 3px;
    background-color: #fff;
    line-height: 1.1em;
    word-wrap: nowrap;


    hr {
        margin: 0;
    }

    .menu {
        padding: 7px;
        color: #000;

        &.submenu {
            padding: 5px 7px;

        }

        >label {
            font-weight: 500;
            display: block;
            padding: 10px;
        }

        .option {
            border-radius: 3px;
            padding: 0 10px;
            margin: 0 0 3px;
            border: solid 1px transparent;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            &:hover {
                background-color: #dedede;
            }

            &:last-child {
                margin: 0;
            }

            &.active {
                >span {
                    cursor: default;
                }

                background-color: $blue-shadow-darker;
            }

            &.selected {
                /*border:solid 1px $blue-shadow-darker;*/
            }

            >strong {
                font-weight: 500;
                font-size: 1em;
            }

            >span {
                cursor: pointer;
                display: block;
                padding: 8px 0;
                width: 190px;
            }

            >button {
                border: none;
                padding: 0;
                margin: 0;
                background: none;
                font-family: inherit;
                font-size: .9em;
                cursor: pointer;
                height: 38px;
            }
        }
    }
}

.context {
    @include noselect;
    @include shadow-1dp;
    position: absolute;
    min-height: 30px;
    width: 130px;
    background-color: #fff;
    z-index: 1001;
    border-radius: 4px;
    padding: 4px;
    right: -133px;
    top:-2px;
    .option {
        display: flex;
        align-items: center;
        color: #626262;
        padding: 10px;
        cursor: pointer;
        margin: 0 0 3px;
        border-radius: 3px;

        svg {
            margin-right: 15px;
            font-size: 16px;
        }

        span {
            display: block;
        }

        &:hover {
            background-color: #EBEBEB;
        }

        &:last-child {
            margin: 0;
        }
    }
}