@use './colors' as *;
@use './mixins' as *;

.container {

    width: 400px;

    @include shadow-2dp;
    background-color: $gray-0;

    position: relative;


    .header {
        margin-bottom: 2px;

        .f {
            display: flex;
        }

        .g {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            padding: 10px;
            color: #626262;

            span {
                font-size: .89em;
                font-weight: 600;
            }

            .iconButton {
                cursor: pointer;
                color: inherit;
                border: none;
                background: transparent;
                margin-right: 5px;
                font-size: 1em;

                &:last-child {
                    margin-right: unset;
                }

                &:hover {
                    &.trash {
                        color: $red-dark;
                    }
                }

                &>span {
                    font-size: 1.1em;
                }
            }
        }
    }

    .body {

        .q {
            display: flex;
            height: 100%;

            .c {
                .tabs {

                    width: 100px;
                    flex-shrink: 0;
                    background-color: $blue-shadow;
                    height: inherit;
                    margin-right: 1px;
                    min-height: 450px;

                    button {
                        &.tab {
                            border: none;
                            display: block;
                            padding: 12px 6px;
                            background: transparent;
                            cursor: pointer;
                            width: 100%;

                            &:hover {
                                background-color: $blue-shadow-darker;
                            }
                        }

                        &.active {
                            cursor: default;
                            color: #fff;
                            background-color: $blue;

                            &:hover {
                                background-color: $blue;
                            }
                        }

                        &.disabled {
                            cursor: not-allowed;
                            color: #999;

                            &:hover {
                                background-color: unset;
                            }
                        }

                        &.dirty {
                            position: relative;

                            ::after {
                                top: 16px;
                                right: 7px;
                                display: block;
                                content: ' ';
                                position: absolute;
                                height: 8px;
                                width: 8px;
                                border-radius: 50%;
                                background-color: rgb(252, 130, 49);
                            }
                        }

                        &.warn{
                            position: relative;

                            ::after {
                                top: 16px;
                                right: 7px;
                                display: block;
                                content: ' ';
                                position: absolute;
                                height: 8px;
                                width: 8px;
                                border-radius: 50%;
                                background-color:#B21E1E;
                            }
                        }
                    }
                }

                .saveButton {
                    display: flex;
                    width: 100%;
                    padding: 10px;
                    border: none;

                    text-align: center;
                    background-color: #D3E4ED;
                    color: $blue-vivid;
                    align-items: center;
                    cursor: pointer;
                    justify-content: center;


                    &:hover {
                        svg {
                            transform: translate(0, 2px);
                        }
                    }

                    &:active {
                        filter: brightness(98%);

                        span {
                            filter: brightness(80%);
                        }
                    }

                    svg {
                        transition: all linear 0.1s;
                        flex-shrink: 0;
                        font-size: 1.2em;
                        margin-right: 5px;

                    }

                    span {
                        font-weight: bold;
                        font-size: 1.1em;
                        display: block;

                    }

                }
            }

            .options {
                width: 100%;
                padding: 10px;
                background-color: #fff;
                max-height: 488px;
                overflow: auto;
            }
        }

    }
}