@use '../../../scss/colors' as *;

.container {
    header {
        background-color: $blue;
        border-bottom: solid 1px #ccc;
        color: #fff;

        .section {

            padding: 10px;
            height: 50px;

            &.logo {
                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .page {

        .graphics {
            margin: 150px auto 0;
            height: 202px;
            width: 205px;
            background: url('assets/stars.png');
            display: flex;

            &::after {
                content: ' ';
                display: block;
                background: url('assets/ghost.png');
                height: 92px;
                width: 69px;
            }

            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .message {
            text-align: center;

            h3 {
                font-size: 19px;
                color: $blue-dark2;
            }

            p {
                color: $blue-dark2;
                font-size: 16px;
            }

            width: 202px;
            margin:20px auto 0;

            .back {
                margin-top: 20px;
                position: relative;
                left: 10px;
                font-weight: bold;
                a {
                    cursor: pointer;
                    display: block;
                    text-decoration: none;
                    background-color: $blue;
                    color: #fff;
                    width: 183px;
                    padding: 12px 0;
                    text-align: center;
                    border-radius: 6px;
                    font-size: 15px;

                    &:hover {
                        filter: brightness(110%);
                    }

                    &:active {
                        filter: brightness(90%);
                    }
                }
            }
        }
    }
}