@use "../../scss/colors" as *;

.container{
    padding: 20px .6rem;

    .requirements{
        margin: 5px 0 0 0;
        font-size: .74em;
        color: "#777";
        font-style: italic;

        >span{
            display: block;
            text-align:right;
        }
    }

    .warning{
        font-style: unset;
        color:$red-dark;
    }
}