@use './mixins' as *;

.container{


    padding:20px;
    h3{
        margin-bottom: 50px;
    }
}

.multiringcontainer{
    display: flex;

    .widget{
        @include noselect;
        @include shadow-active-2pd;
        margin-left: 30px;
        width: 300px;
        background-color: #fff;
    }
    .resizewidget{
        @include noselect;
        @include shadow-active-2pd;
        margin-left: 30px;        
        background-color: #fff;
        height: inherit;
        overflow: hidden;
    }
}

