@use './colors' as *;


header {
    background-color: $blue;

    border-bottom: solid 1px #ccc;
    color: #fff;

    .container {

        display: flex;
        align-items: center;

        .relative{
            position: relative;
        }
        .section {

            padding: 10px;
            height: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >div {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }


            span {
                display: inline-block;
                font-size: 15px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .headerIcon {
                margin-right: 10px;
                cursor: pointer;
            }

            &.separator {
                position: relative;

                &::after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;

                    width: 1px;
                    background-color: #fff;
                    opacity: .22;
                }
            }

            &.logo {   
                svg {
                    margin-right: 10px;
                }

                >div {
                    justify-content: start;
                    width: 300px;

                    span {
                        font-size: .92em;
                        margin: 0 0 3px 15px;
                    }
                }
            }

            &.dashboards {
                width: 100%;
            }

            &.testOption {
                >div {
                    font-size: inherit;
                    width: 100px;
                    flex-shrink: 0;
                }

                &:hover {
                    >div {
                        opacity: 0.7;
                        color: white;
                        cursor: pointer;
                    }
                }
            }

            &.region {
                >div {
                    font-size: inherit;
                    width: 250px;
                    flex-shrink: 0;
                }
            }

            &.designMode {
                    width: 40px;
                    flex-shrink: 0;
                    margin-right: 10px;                    
                    margin-left: 10px;
                    
            }
            &.help {
                >div {
                    width: 120px;
                    flex-shrink: 0;
                }
            }

            &.user {
                >div {
                    width: 110px;
                    flex-shrink: 0;
                }
            }
        }

        a {
            cursor: pointer;

            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        span{
            &.info {
                cursor: pointer;                
            }
        }

        .dashboardoptions{
            z-index: 10;
            transform: translate(-140px,14px);
        }
        .useroptions{
            z-index: 10;
            transform: translate(-250px,14px);
        }
    }

 
}