@import "../../scss/colors";
@import "../../scss/_mixins";

.container {
    @include shadow-1dp;
    @include noselect;
    position: absolute;
    z-index: 1000;
    left: 0;
    min-width: 250px;
    max-width: 250px;

    border-radius: 3px;
    background-color: #fff;
    line-height: 1.1em;
    word-wrap: nowrap;
    overflow: hidden;

    color: #000;

    .menu {

        .option{
            a,span{
                font-size: .9em;
                
            }
            a {
   
                color:inherit;
                text-decoration: none;
                cursor: pointer;
    
                &:hover {
                    text-decoration: underline;
                }
            }

            &:hover {
                background-color: #dedede;
            }

            padding: 20px 0px 20px 15px;
            border-bottom: silver solid 1px;

            label, span{
                display: block;
            }
            label{
                font-weight: 500;
                margin-bottom:5px;

                a{
                   font-size: medium; 
                }
            }
            
            div {
                font-size: 0.9em;
            }
            div:hover {
                text-decoration: underline;
            }
        }
    }
}