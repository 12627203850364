@use './colors' as *;
.overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    position: absolute;
    opacity: .4;
    background-color: #333;
}

.pickercontainer {
    position: absolute;
    top: 44px;
    left: 101px;
    z-index: 101;
    background-color: #fff;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .l {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
            font-size: .8em;
            color: $blue-dark;

            label {
                display: inline-block;
                padding: 4px;
                text-align: center;
            }

            u {
                display: inline-block;
                max-width: 110px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: help;
            }
        }

        button {
            flex-shrink: 0;
            border: none;
            cursor: pointer;
            background: none;

            svg {
                font-size: .9em;
                color: $red-dark;

            }

            &:hover {
                filter: brightness(200%);
            }
        }

    }

    .demoarea {
        height: 36px;
        display: flex;
        justify-content: flex-end;

        &>div {
            height: inherit;
            padding: 0 0 0 1px;
            background-color: #fff;

            button {
                cursor: pointer;
                height: inherit;
                display: block;
                border: #ccc;
                width: 60px;
                background-color: $blue-dark;
                color: #fff;

                &:hover {
                    filter: brightness(110%);
                }
            }
        }
    }
}

.colorscontainer {
    display: flex;
    flex-wrap: wrap;
  

    .colorvalue {
        width: 100%;
 
        margin: 0 5px 5px 0;
        cursor: pointer;

        section {
            display: block;
            border-radius: 2px;
            height: inherit;
            width: inherit;
            height: 31px;
        }

        label {
            display: block;
            margin: 6px 0 0 2px;
            font-size: .84em;
            color: #555;
            width: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: help;
        }
    }

}