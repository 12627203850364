@import '_colors';

:root {
    --borderColor: transparent;
  }

.react-grid-item {
    border: solid 1px transparent;
    >.react-resizable-handle {
        opacity: 0%;
        &::after{
            border:none;

            height:14px;
            width: 14px;
            border: solid 1px $blue-resize;
            border-radius: 50%;
            background: none;
            background-color: white;
            bottom: -8px;
            right: -8px;
        }

        &.react-resizable-handle-n {
            background-image: none;    
        }

        &.react-resizable-handle-e {
            background-image: none;
        }

        &.react-resizable-handle-w {
            background-image: none;
        }

        &.react-resizable-handle-s {
            background-image: none;
        }

        &.react-resizable-handle-se {
           background-image: none;
        }

        &.react-resizable-handle-sw {
            background-image: none;
        }

        &.react-resizable-handle-nw {
            background-image: none;
        }

        &.react-resizable-handle-ne {
            background-image: none;
        }
    }
    &:hover{
        .react-resizable-handle{
            opacity: 100%;
        }
        border-color:var(--borderColor);
    }
}