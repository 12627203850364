.notificationcontainer {
    top: 66px;
    right: 0;
    width: 295px;
    min-height: 75px;
    border-radius: 12px;
    margin: 10px 70px 0 -70px;
    position: fixed;
    z-index: 3;

    &.backgroundError {
        background-color: pink;
    }

    &.backgroundWarning {
        background-color: orange;
    }

    &.backgroundInformation {
        background-color: lightblue;
    }

    &.backgroundConfirmation {
        background-color: lightgreen;
    }

    .circle {
        display: inline-block;
        height: 27px;
        width: 27px;
        border-radius: 15px;
        margin: 22px 10px;

        &.circleError {
            background-color: red;
        }

        &.circleWarning {
            background-color: orangered;
        }

        &.circleInformation {
            background-color: darkblue;
        }

        &.circleConfirmation {
            background-color: white;
        }
    }

    .triangle {
        color: white;
        padding: 4px 0 0 5px
    }

    .info {
        color: white;
        padding: 4px 0 10px 10px;
    }

    .confirmation {
        color: green;
        height: 100%;
        width: 100%;
    }

    .close {
        position: fixed;
        width: 100%;
        margin-left: 275px;
        margin-top: -70px;
        padding-top: 5px;
        cursor: pointer;
    }

    .message {
        padding: 20px 15px;
        margin-left: 35px;
        margin-right: 10px;
        margin-top: -70px
    }
}

.notificationcontainerbottom {
    bottom: 25px;
    left: 100px;
    width: 380px;
    min-height: 100px;
    background-color: white;
    margin: 10px 70px 0 -70px;
    position: fixed;
    z-index: 3;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;

    .close {
        display: flex;
        justify-content: right;
        padding: 5px 5px 0px 0px;
    }

    .bodyContainer {
        display: flex;
        padding-top: 10px;

        .starContainer {
            padding: 0px 10px 0 15px;
            color: #2980B9;
        }

        .messagecontainer {
            padding-top: 2px;
            padding-right: 20px;

            .title {
                font-weight: bold;
                padding-bottom: 10px;
                color: #2C3E50;
                font-size: 13px;
            }

            .message {
                font-size: 12px;
            }

            .additional {
                font-size: 12px;
                font-style: italic;
                padding-left: 60px;
                padding-bottom: 20px;
            }
        }
    }

    .buttonContainer {
        padding: 15px 0px 0px 60px;

        button {
            border: none;
            outline: none;
            border-radius: 6px;
            font-size: 11px;
        }

        .primary {
            background-color: #2980B9;
            color: white;
            width: 83px;
            height: 25px;
            margin-right: 10px;            
            cursor: pointer;
        }
    }
}

.closeIcon {
    width: 12px;
    height: 12px;
    cursor: pointer;
}