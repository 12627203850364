$blue: #2980B9;
$blue-dark:#20638f;
$blue-dark2:#1E5F8A;
$blue-vivid:#0277CB;
$blue-shadow:#E2F0F8;
$blue-shadow-darker:#d2e8f4;
$blue-shadow-hover:#f2f8fc;
$black:#000000;

$gray-input: #f7f7f7;
$gray-0: #ededed;
$gray-1: #e2e2e2;
$gray-2: #d9d9d9;

$blue-resize: #1E5F8A;

$tooltip-background:#2C3E50;
$tooltip-color: #fff;
$tooltip-background-light-theme: #f3f3f3;
$tooltip-color-light-theme: #333333;

$red-dark: #B21E1E;
$red-threshold: #ED1E1E;

$widget-border:rgba(0, 0, 0, 0.08);

$table-hover-bg: #E2F0F8;
$table-color: #626262;