@use './_colors' as *;

span {
    &.info {
        color: $blue;
        display: block;
        font-size: .7em;
        border: solid 2px $blue;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        text-align: center;
        padding: 1px 0px 0 0;
        margin-left: 10px;
        cursor: help;
    }
}

.ttlabel {
    color: $blue-dark;
    font-size: .84em;
    display: block;
    margin-bottom: 4px;
}