 @use './colors' as *;

 .container {

     background-color: #ffffff;

     h1 {
         margin: 0
     }



     .dashboard {
         width: 100%;
         display: flex;
         justify-content: space-between;
         height: calc(100vh - 51px);
         overflow: auto;

         section{
            flex-shrink: 0;
         }

         .canvas {
            width: 100%;
            height: 100%;
            flex-shrink: 0;
         }
     }


     
     .bc {
         padding: 10px;

         height: inherit;

         box-sizing: border-box;
         clear: both;
     }

     .nav {
         padding: 10px;

         a {
             text-decoration: none;
             cursor: pointer;

             &:hover {
                 text-decoration: underline;
             }
         }
     }
 }