@use '../../../scss/colors' as *;

.inputarea {

    &.data {
        input {
            margin-top: 10px;
        }
    }

    input {
        width: 30%;
    }

    span {
        display: block;
        margin-top: 6px;
        font-size: .72em;
        color: #555;
    }
}

.sortableList {
    padding: 4px;
    background-color: #F7F7F7;
    border-color: #ededed;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;

    .sortableItem {
        border-radius: 5px;
        background-color: hsl(0, 0%, 90%);
        cursor:move;
        border: dotted rgb(143, 143, 143) 1px;
        margin: 5px;
        height: 35px;
        width: 260px;
        color: hsl(0, 0%, 20%);        
        display : flex;
        align-items : center;
        text-align : center;
        .arrowIcon {
            padding: 5px;
            width: 15px;
            display: inline-block;
        }
        
        .sortableItemText {
            font-family: "Roboto", "Helvetica Neue", sans-serif;
            font-size: 10pt;
            width: 210px;
            text-overflow: ellipsis;
            display: inline-block;
            
        }
    }

}

.subtitleConfig {

    .row {
        margin: 10px 0 0;
        display: flex;
        justify-content: space-between;

        label {
            color: $blue-dark;
            font-size: .84em;
            display: block;
            margin-bottom: 4px;
        }
        .defaultName {
            padding-top: 2px;
            font-size: .72em;
            color:rgb(83, 83, 83);            
            text-decoration: underline;
        }

        .name {
            flex-shrink: 0;
            margin-right: 5px;
            width: 75%;
        
            >div{
                display: flex;
                justify-content: space-between;
                span{
                    color:$blue;
                    cursor: pointer;
                    font-size: .74em;
                    margin: 0 5px 0 0;
                    text-decoration: underline;
                }
            }

            input[type="text"] {
                margin: 0 0 10px;
                height: 31px;
            }
        }
    }
}
.fontcontrol {
    width: 100%;
    margin: 10px 0 0;    

    .fontType {
        width: 80%;
    }

    .sizeAndColor
    {
        display: flex;
        padding-top:15px;

        .option {        
            margin-right: 5px;

            &.size {
                width: 40%;
            }
            &.color {
                width: 40%;
            }
        }    
    }
}