@use './mixins' as *;
@use './colors' as *;

.widget{

    height: 100%;
    width: 100%;

    @include noselect;    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;

    height: inherit;
    width: inherit;

    border: solid 1px #ccc;
 
 
    .content{
        height: inherit;
        width: inherit;

        font-size: .9em;
        color:#121d96;
        text-align: center;

        label{
            display: block;
            color:$blue-dark;
            font-size: 1em;
            font-weight: bold;
            margin-bottom:15px;
         
          
        }

        span{
            cursor: pointer;

            color:$blue-dark;
            font-weight: bold;
            font-size: 2em;
        }
    }

    .empty{
        height: inherit;
        width: inherit;

        font-size: .9em;
        color:#5b94ba;
        text-align: center;

        label{
            display: block;
            color:$blue-dark;
            font-size: 1em;
            font-weight: bold;
            margin-bottom:15px;
            position: relative;
            top: 8px;
        }

        span{
            background: url('../icons/caution.svg');
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: top;
            padding-top: 27px;
            position: relative;
            top: 35%;
            color:#5b94ba;
            font-size: 1em;
        }
    }
    .filtered{
        height: 100%;
        width: 100%;

        font-size: .9em;
        color:#5b94ba;
        text-align: center;
        justify-content: center;
        align-items: start;
        background-color: #f0f0f0 !important;
        overflow: hidden;

        label{
            color:#a5a5a5;
            display: block;
            font-weight: bold;
            margin-bottom:10px;            
            padding-right: 6px;
            padding-left: 6px;
        }
        
        .exclamationIcon{
            width: 100%;
            display: block;
        }
        .title{
            padding-top: 8px;
            color:#b4b4b4;
            font-size: 0.8em;
            display: block;            
        }  
        .line1{
            padding-top: 15px;
            color:#8d8d8d;
            font-size: 1.1em;
            display: block;
        }  
        .line2, .line3{
            padding-top: 5px;
            color:#a5a5a5;
            font-size: 0.86em;
            display: block;
        }  

    }  
}