@import '_colors';
@import '_mixins';

.App {
    text-align: center;
    padding-top: 2rem;
}

.modaloverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .4;
}

.modalwrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    .modal {
        z-index: 100;
        background: white;
        position: relative;
        margin: 5.75rem auto;
        margin-top: 10%;
        border-radius: 3px;
        max-width: 500px;
        @include shadow-2dp;

        .header {
            padding: 0 .6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 2rem;

            .cancelbtn {
                font-size: 2rem;
                font-weight: 500;
                line-height: 1;
                color: #333;
                opacity: .8;
                cursor: pointer;
                border: none;
                background: none;
                user-select: none;
                padding:0;
            }

            .cancelbtn:hover {
                opacity: .3;
            }
        }

        .messageSection {
            margin-top: 1rem;
        }

        .buttonsSection {
            display: flex;
            justify-content: flex-end;
            margin-top: .5rem;
            padding: 0 .6rem .3rem;

            .modalButton {
                cursor: pointer; 
                border: solid 1px $gray-2;
                border-radius: 3px;
                padding: .5rem 1rem;
                margin-left: .6rem;
                background-color: $gray-0;
                color: #333;

                &.ok {
                    border-color: $blue-vivid;
                    background-color: $blue-vivid;
                    color: #fff;

                    &:disabled{
                        border-color:#777;
                        background-color:#aaa;
                        cursor:not-allowed;
                    }
                }

                &.cancel {
                    &:hover {
                        filter: brightness(95%)
                    }

                    &:active {
                        filter: brightness(102%)
                    }
                }

                &:hover {
                    filter: brightness(110%)
                }

                &:active {
                    filter: brightness(90%)
                }
            }


        }
    }
}