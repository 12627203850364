@use './colors' as *;
@use './mixins' as *;

.container {
    @include noselect;
    background-color: #fff;
    width: 250px;
    -webkit-box-shadow: 2px 0px 5px 0px rgba(187, 187, 187, 0.39);
    box-shadow: 2px 0px 5px 0px rgba(187, 187, 187, 0.39);



    .heading {
        background-color: $blue-dark;
        color: #fff;
        padding: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: .9em;
            font-weight: normal;
            margin: 0;
            padding: 0;
            line-height: normal;
        }

        .h_right {
            display: flex;
            padding: 0 10px;

            .menu {
                cursor: pointer;

                span {
                    display: block;
                    height: 2px;
                    width: 16px;
                    border-radius: 2px;
                    margin-bottom: 3px;
                    background-color: #fff;
                    margin-left: 15px;
                }
            }
        }

        .chevronLeft {
            cursor: pointer;
            transform: translate3d(125%, 0, 0);
            z-index: 100;
        }

        .chevronRight {
            cursor: pointer;
            transform: translate3d(100%, 0, 0);
            z-index: 100;
        }
    }

    &.collapsed {
        width: 60px;

        &:hover {
            opacity: 100%;
        }
    }


    .section {
        display: flex;
        justify-content: space-between;
        color: #444;
        padding: 20px 20px;
        cursor: pointer;
        font-size: .9em;

        &:hover {
            background-color: $blue-shadow;
        }

        span {

            &.expanded {
                width: 100%;
                text-align: center;
                color: black;
                font-weight: bold;
            }

            &.info {
                cursor: help;
                border: solid 2px $blue;
                border-radius: 50%;
                height: 18px;
                width: 18px;
                text-align: center;
                font-size: .9em;
                padding: 1px 1px 0 0;

            }
        }

        .rightgroup {
            width: 36px;
            display: flex;
            position: relative;

            justify-content: space-between;
            align-items: center;

            svg {
                color: #555;
                font-size: .9em;
            }
        }

        &.expanded {
            border-bottom: solid 1px #eee;
        }

        .imageGrouping {
            padding-right: 10px;
        }
    }

    .widgets {
        background-color: #f3f3f3;
        height: calc(100vh - 190px);
        min-height: 100px;
        overflow: auto;
        padding-top: 20px;
    }

    .widgetWrapper {
        position: relative;

        &::before {
            content: ' ';
            position: absolute;
            z-index: 1000;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
        }

        overflow: hidden;
        background-color: #fff;
        @include shadow-2dp;
        width: 180px;
        height: 180px;
        margin: 0 auto 20px;
        overflow: hidden;

        transition: all linear .1s;
        cursor: move;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        >div {
            width: 100%;

            >svg {
                margin: 0 auto;
            }
        }

        p {
            &.widgetName {
                text-align: center;
                padding-top: 10px;
                font-weight: bold;
            }

            &.widgetDescription {
                padding-top: 5px;
                padding-right: 20px;
                padding-left: 20px;
                text-align: center;
            }
        }


        &:hover {
            @include shadow-active;
            width: 190px;
            height: 190px;
        }
    }
}